import React, { useEffect, useRef, useState } from "react"
import { Box, Button, Grid, Link, List, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from "@mui/material"
import { PageTemplate } from "../PageTemplate"
import { useLocation } from "react-router-dom"
import spectraviewThumbnail from "res/assets/Infographics/spectraview_desktop_thumbnail.png"
import hydrascreenThumbnail from "res/assets/Infographics/hydrascreen_desktop_thumbnail.png"
import { ReactComponent as BulletIcon } from "res/assets/Icons/Groundwork – 1.svg"

import "styles/services.scss"
import { useTheme } from "@mui/material/styles"
import Paper from "@mui/material/Paper"

const BUTTON_SX = { borderRadius: "100px", px: "1.5rem", py: "0.75rem", fontFamily: "RubikBold", letterSpacing: "0.1rem" }

function CustomizedListItem ({ children }) {
  const theme = useTheme()

  return <ListItem>
    <ListItemIcon><SvgIcon sx={{ width: "2rem", height: "2rem" }}><BulletIcon width="inherit" height="inherit"
                                                                            fill={theme.palette.custom.primaryBlue} /></SvgIcon></ListItemIcon>
    <ListItemText><Typography variant="h6" component="span">{children}</Typography></ListItemText>
  </ListItem>
}

function TitleWithBoldMiddle ({ firstPart, boldPart, lastPart, color }) {
  const theme = useTheme()
  if (color == null) {
    color = theme.palette.custom.primaryBlue
  }

  return <Typography color={color} sx={{ mb: "1.5rem", px: "1rem" }}>
    <Typography variant="h2" component="span" fontWeight={500}>{firstPart} </Typography><Typography
    variant="h2" component="span" fontWeight={100} sx={{ mb: "1rem", fontFamily: "RubikBold" }}>{boldPart}</Typography>
    {!!lastPart && <Typography variant="h2" component="span" fontWeight={500}> {lastPart}</Typography>}
  </Typography>
}

function CallToActionButton ({ href, text }) {
  const theme = useTheme()

  return <Button component={Link} variant="contained" href={href} target="_blank" rel="noreferrer"
                 sx={{ ...BUTTON_SX, m: "2rem", "&": { [theme.breakpoints.down("md")]: { mx: "auto", display: "block", textAlign: "center" } } }}>
    <Typography color="white">{text}</Typography>
  </Button>
}

function SpectraViewPage () {
  const theme = useTheme()

  return <>
    <Grid container direction="row" sx={{
      "&": {
        [theme.breakpoints.up("md")]: { marginLeft: "12vw", marginBottom: "8vh", marginTop: "3rem" }
      }
    }}>
      <Grid item sm={11} md={5}>
        <TitleWithBoldMiddle firstPart="Transform the way you explore biomedical data using" boldPart="SpectraView" />

        <Box sx={{ marginLeft: "2rem", marginBottom: "2rem", marginTop: "0rem", "&": { [theme.breakpoints.up("md")]: { display: "none" } } }}>
          <img src={spectraviewThumbnail} alt="SpectraView Preview" style={{ width: "120%" }} />
        </Box>

        <Box sx={{ mx: "1rem" }}>
          <Typography variant="h5">SpectraView is a target evaluation and drug discovery research platform that facilitates biomedical data
            exploration. Choose your drug target and view comprehensive, interactive charts and tables for relevant queries.
          </Typography>
          <List>
            {["Serve scientists in data-driven decision-making.",
              "Provides biomedical data reports that break traditional biology, chemistry, and business silos.",
              "Covers research, intellectual property, and competitive landscape considerations.",
              "Provides all source data - no black-box predictions.",
            ].map((text, i) => <CustomizedListItem key={i}>{text}</CustomizedListItem>)}
          </List>
          <CallToActionButton href={"https://spectraview.ro5.ai?ref=ro5.ai"} text={"Try it out now"} />
        </Box>
      </Grid>
      <Grid item md={5} sx={{ marginBottom: "8vh", "&": { [theme.breakpoints.down("md")]: { display: "none" } } }}>
        <img src={spectraviewThumbnail} alt="SpectraView Preview" style={{ marginTop: "1rem", marginLeft: "14vw", width: "50vw" }} />
      </Grid>
    </Grid>

    <div style={{
      position: "relative",
      paddingTop: "3rem",
      paddingBottom: "8vh",
      width: "100%",
    }} className="blueBg">
      <div className="blueBgFlex">
        <div className="col-md-8">
          <center className="blueBgSectionName">
            <TitleWithBoldMiddle firstPart="How" boldPart="SpectraView" lastPart="Works" color="white" />
          </center>
          <center style={{ aspectRatio: 1.78, width: "100%" }}>
            <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/fdpJfCjwYjs?si=tAA2G8EFU4tXfjgO&amp;controls=0"
                    title="How Ro5 SpectraView Works" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
          </center>
        </div>
      </div>
    </div>
  </>
}

function HydraScreenPage () {
  const theme = useTheme()

  return <>
    <Grid container direction="row" sx={{
      marginTop: "3rem",
      "&": {
        [theme.breakpoints.up("md")]: { marginLeft: "12vw", marginBottom: "8vh", marginTop: "3em" }
      }
    }}>
      <Grid item md={5} sx={{ "&": { [theme.breakpoints.down("md")]: { display: "none" } } }}>
        <img src={hydrascreenThumbnail} alt="HydraScreen Preview"
             style={{ width: "50vw", marginLeft: "-18vw", marginTop: "1rem", overflow: "hidden" }} />
      </Grid>
      <Grid item sm={11} md={5}>
        <TitleWithBoldMiddle firstPart="Use AI to predict protein-ligand affinity and pose score with" boldPart="HydraScreen" />

        <Box sx={{ marginLeft: "2rem", marginBottom: "2rem", marginTop: "0rem", "&": { [theme.breakpoints.up("md")]: { display: "none" } } }}>
          <img src={hydrascreenThumbnail} alt="HydraScreen Preview" style={{ width: "120%" }} />
        </Box>

        <Box sx={{ mx: "1rem" }}>
          <Typography variant="h5">
            HydraScreen is based on a state-of-the-art model that uniquely employs a multimodal hybrid architecture combining 3D-convolutional neural
            networks (CNNs) and transformers to efficiently encode protein-ligand complexes and their interactions.
          </Typography>
          <List>
            {["Provides accurate protein-ligand affinity and pose predictions.",
              "Achieves state-of-the-art performance on CASF benchmark (Affinity: Pearson’s R 0.86, Pose: Top 3 accuracy>99%).",
              "Is generalizable across proteins, ligands, and different types of interactions.", "Can be readily applied in your virtual screening and hit-to-lead campaigns.",
            ].map((text, i) => <CustomizedListItem key={i}>{text}</CustomizedListItem>)}
          </List>
          <CallToActionButton href={"https://hydrascreen.ro5.ai?ref=ro5.ai/"} text={"Try it out now"} />
        </Box>
      </Grid>
    </Grid>

    <div style={{
      position: "relative",
      paddingTop: "3rem",
      paddingBottom: "8vh",
      width: "100%",
    }} className="blueBg">
      <div className="blueBgFlex">
        <div className="col-md-8">
          <center className="blueBgSectionName">
            <TitleWithBoldMiddle firstPart="How" boldPart="HydraScreen" lastPart="Works" color="white" />
          </center>
          <center style={{ aspectRatio: 1.78, width: "100%" }}>
            <iframe width="100%" height="100%" src="https://www.youtube-nocookie.com/embed/_l4wrt8wU40?si=djHypLliBQswhV8s&amp;controls=0"
                    title="How Ro5 Hydrascreen Works" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
          </center>
        </div>
      </div>
    </div>
  </>
}

function AppIntroWidget ({ title, shortIntro, pagePartRef }) {
  return <Grid item sm={12} md={5}>
    <Paper sx={{ p: "2rem", opacity: "1", background: "#FFFFFF26", textAlign: "left", height: "100%", borderRadius: "1.5rem" }}>
      <Typography variant="h3" color="white" align="left" gutterBottom fontWeight={700}>
        {title}
      </Typography>
      <Typography variant="h6" color="white" align="left">
        {shortIntro}
      </Typography>
      <Button variant="contained"
              color="whiteOnBlue"
              sx={BUTTON_SX}
              onClick={() => {
                window.scrollTo({
                  top: pagePartRef.current.offsetParent.offsetTop + pagePartRef.current.offsetTop,
                  behavior: "smooth"
                })
              }}>Find out more</Button>
    </Paper>
  </Grid>
}

function Applications () {
  const theme = useTheme()
  const location = useLocation()
  const [loaded, setLoaded] = useState(null)
  const SpectraViewPagePart = useRef(null)
  const HydraScreenPagePart = useRef(null)

  useEffect(() => {
    if (location.state == null || location.state.pagePart == null) {
      return
    }
    if (
      SpectraViewPagePart.current &&
      HydraScreenPagePart.current
    ) {
      switch (location.state.pagePart) {
        case "SpectraView":
          window.scrollTo({
            top: SpectraViewPagePart.current.offsetParent.offsetTop + SpectraViewPagePart.current.offsetTop,
            behavior: "smooth"
          })
          break
        case "HydraScreen":
          window.scrollTo({
            top: HydraScreenPagePart.current.offsetParent.offsetTop + HydraScreenPagePart.current.offsetTop,
            behavior: "smooth"
          })
          break
        default:
          break
      }
    }
  }, [loaded, location])

  return (
    <PageTemplate>
      <center
        className="historyHeader"
        style={{
          position: "relative",
          marginBottom: 0,
        }}
      >
        <div className="serviceSectionTitleLanding">Applications</div>
        <div className="servicesStory">
          Unlock the full potential of AI-driven drug discovery using Ro5’s scientific software. Ro5’s applications are crafted to simplify complex
          research problems and empower scientists to generate data-driven insights. Whether you're working in the fields of biology, chemistry, or
          data science, our software is designed to transform your research workflows and deliver impact today.
        </div>
        <div className="bg-molecule" />
        <div className="bg-rounded-polygon" />
        <div className="bg-polygon-outline" />
        <Grid container direction="row" justifyContent="center" spacing="2rem"
              sx={{ mx: "5vw", "& .MuiGrid-item": { [theme.breakpoints.down("md")]: { paddingLeft: "0px" } } }} marginTop={"2rem"} width={"inherit"}>
          <AppIntroWidget title="SpectraView"
                          shortIntro="SpectraView is a target evaluation and drug discovery research platform that facilitates biomedical data exploration. Choose your drug target and view comprehensive, interactive charts and tables for relevant queries."
                          pagePartRef={SpectraViewPagePart} />
          <AppIntroWidget title="HydraScreen" shortIntro="HydraScreen is based on a state-of-the-art model that employs a multimodal hybrid architecture combining 3D-convolutional neural
                networks (CNNs) and transformers to efficiently encode protein-ligand complexes and their interactions."
                          pagePartRef={HydraScreenPagePart} />
        </Grid>
      </center>

      <div className="servicesHolder">
        <div style={{ position: "relative" }}>
          <div style={{ paddingBottom: "1.5rem" }} />
          <div className="allServicesList">
            <div onLoad={() => setLoaded(true)} ref={SpectraViewPagePart}>
              <SpectraViewPage />
            </div>
            <div onLoad={() => setLoaded(true)} ref={HydraScreenPagePart}>
              <HydraScreenPage />
            </div>
          </div>

        </div>
      </div>
    </PageTemplate>
  )
}

export default Applications
