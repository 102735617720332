/**
 * Data for each team member in company
 *
 * @author Amit Setty
 */

import React from "react";
const teamData = [
  {
    name: "Charles Dazler Knuff",
    branch: "Founding CEO",
    role: "founder",
    headline: null,
    content: (
      <>
        Charles has founded software development companies in the adjacent
        fields of digital communications and machine learning. He coauthored
        multiple patents in telecommunications and ten patents in machine
        learning and related fields.
        <br />
        <br />
        As the founder of Forté, he and his team developed configuration and
        network analytics software licensed globally by Nortel Networks.
        Genesys later acquired Forte, then Alcatel, where Charles led as
        division president.
        <br />
        <br />
        Concurrent with this work, he also participated in the UCSD Supercomputer
        Center’s exploratory work in computational biology, protein modeling,
        and early work in artificial intelligence.
        <br />
        <br />
        His personal experience of undergoing chemotherapy and a stem cell
        transplant reignited his efforts to accelerate drug discovery.
        Ro5 was founded to develop AI and data science models for de novo
        drug discovery, bioactivity, and ADME-T for the bio/pharma ecosystem.
        <br />
        <br />
        Charles is an alpine climber, pick-up basketball habitué, and amateur
        cellist. For many summers, he was a counselor at a camp for children
        with cancer.
      </>
    ),
  },
  {
    name: "Sarah Flatters, Ph.D.",
    branch: "Principal Pharmacologist",
    role: "academic",
    headline: "Clinical Trials",
    content: (
      <>
        Sarah Flatters began her career at University College London (UCL) with
        a BSc (Hons) in pharmacology followed by a Ph.D. in pharmacology and
        neuroscience between Novartis and UCL. Her work included prestigious
        fellowships at McGill University in Canada, and at Harvard Medical
        School, leading to a tenured position at King’s College London
        researching chemotherapy-induced neuropathy. Sarah both led and
        collaborated on interdisciplinary projects with industrial, academic,
        and National Health Service (NHS, UK) partners.
        <br />
        <br />
        She is an experienced communicator to both expert and layperson
        audiences, through invited talks at international conferences, research
        publications, funding pitches and teaching. Sarah’s scientific research
        has produced 29 peer-reviewed articles, so far. She has developed
        innovative methods to teach aspects of pharmacology and neuroscience to
        school children, undergraduate, medical, and postgraduate students.
        Sarah is a fellow of the Higher Education Academy, UK, and has served as
        an editor at the British Journal of Pharmacology.
        <br />
        <br />
        Analysis of complex patient data drew Sarah’s focus to the advantages of
        using data science in understanding medical problems. In 2021, she left
        academia to fully focus on data science, completing two competitive
        fellowships working on real-world projects in telecom and climate
        change.
        <br />
        <br />
        Away from the laptop, Sarah enjoys running, tennis, and baseball.
      </>
    ),
  },
  {
    name: "Tanya Paquet, Ph.D.",
    branch: "Principal Computational Medicinal Chemist",
    role: "academic",
    headline: "AI Chemistry",
    content: (
      <>
        Tanya is an experienced chemist who has coordinated multi-national
        collaborations in drug discovery for programs with the Medicine for
        Malaria Venture. Among these was MMV048, a compound designed to offer a
        single dose cure through a novel mechanism of action, which formed a key
        contribution from South Africa to the global fight against malaria.
        <br />
        <br />
        Her {">"}20 experience as a chemist covers a broad range of skills, from
        integrated compound design to multi-step organic synthesis. Previously,
        Tanya was the team lead and medicinal chemistry expert for antimalarial
        research and development projects in partnership with Merck and
        Novartis. She led the optimization of four chemical series from hit
        identification through to the identification of lead and late lead
        compounds.
        <br />
        <br />
        Tanya authored or co-authored 21 peer-reviewed publications showcasing
        medicinal chemistry projects and complex synthetic chemistry, also
        drafting investigation brochures for multiple preclinical candidates.
        <br />
        <br />
        She has also spent years as a data scientist in industry, where she
        cycled through the development, productionization and application of AI
        models. The contributions of her work were honored with the UK IoT App
        of the Year award, and highly commended in the UK IT Industry AI/ML App
        of the Year award in 2020.
        <br />
        <br />
        Tanya has a PhD in Chemistry from the University of Cambridge, an MSc in
        Chemistry from the University of Cape Town, South Africa, and a double
        BSc in Biochemistry and Chemistry at the University of Pretoria, South
        Africa.
      </>
    ),
  },
  {
    name: "Hisham Abdel Aty, Ph.D.",
    branch: "Principal Computational Chemist",
    headline: "AI Chemistry",
    role: "academic",
    content: (
      <>
        Hisham received his MSci in Chemistry with Molecular Physics from
        Imperial College London. He is currently pursuing a PhD, researching the
        applications of Machine Learning and AI in chemistry and chemical
        simulations. Hisham has a great passion for automation, whether it’s in
        the lab or at home!
        <br />
        <br />
        In his downtime, you’ll find him either playing with electronics for a
        home automation project or watching a good film.
      </>
    ),
  },
  {
    name: "Roy Tal",
    branch: "Cofounder - Pharmacology & Data Science",
    role: "founder",
    headline: null,
    // cofounder: "Cofounder",
    content: (
      <>
        Roy’s primary responsibility at Ro5 is to lead the company’s scientific
        research & development operations, in addition to general executive
        decision-making, business development, strategic planning, establishing
        industry & academic collaborations, and managing customer relationships.
        His expertise in both biomedical science and machine learning has given
        him the ability to bridge the gap between both domains and manage the
        cross-disciplinary scientific teams at Ro5. Namely, this involves R&D in
        the domains of chemistry, biology and pharmacology, as well as the
        application of machine learning methods to these areas.
        <br />
        <br />
        Roy first specialized in pharmacology and neuroscience during his BSc at
        Imperial College London; his primary research focused on the
        glucocorticoid cascade hypothesis and the link between cortisol and
        Alzheimer’s disease. From there, Roy embarked on a journey to understand
        the human body through machine learning, which drove his decision to
        complete a master’s degree in computational neuroscience at University
        College London (UCL), where he graduated top of his class. His
        impassioned research around the application of computational models to
        understand the brain led him to pursue a master’s in artificial
        intelligence and cognitive engineering at l’École Normale Supérieure,
        Paris Sciences et Lettres (ENS-PSL), and more research at the nexus of
        artificial intelligence and cognitive science at Sorbonne Université.
        <br />
        <br />
        Roy’s drive to revolutionize the pharmaceutical industry originated at
        the beginning of his academic journey, where he realized the lack of
        sophisticated computational methods applied to the typical drug
        discovery process, as well as the increasing time and cost associated
        with developing a new drug. His subsequent work in artificial
        intelligence made clear the applications of these powerful techniques in
        the pharmaceutical space, which propelled him to cofound Ro5 and work
        with fellow scientists and engineers at the cutting-edge of this field.
      </>
    ),
  },
  {
    name: "Will Montgomery",
    branch: "General Counsel",
    role: "founder",
    headline: null,
    // branch: "Pharmacology and Data Science",
    content: (
      <>
        For 36 years, after earning his BA and MA from Stanford University
        (English & Modern Thought) and his JD from the University of Chicago Law
        School, Will Montgomery has practiced law in Dallas, Texas. Will tried
        jury and non-jury trials in federal and state courts and arbitrated
        cases across the country. He was also a partner at two law firms for a
        total of over three decades: Jenkens & Gilchrist and Jackson Walker.
        <br />
        <br />
        At Ro5, Will is responsible for general legal affairs, including
        contract drafting and revision, creating foreign subsidiaries, and
        managing outside counsel on special projects.
        <br />
        <br />
        Since 2013, he has served on the Oversight Committee of the Cancer
        Prevention and Research Institute of Texas. Charged to reduce the burden
        of cancer on all who suffer from or because of that disease, CPRIT makes
        $300 million in grants per year for research, prevention, and product
        development to Texas institutions and corporations. Will was elected
        Presiding Officer by his peers for a two-year term from 2017-2019 and
        will conclude a decade of service with CPRIT when his term expires in
        2023.
      </>
    ),
  },
  {
    name: "Orestis Bastas",
    branch: "Principal ML Scientist",
    headline: "AI Chemistry",
    role: "principal scientific",
    content: (
      <>
        At Ro5, Orestis leads our ADME-T platform, developed using pre-trained
        Graph Neural Networks, as well as binding site similarity and
        FASTA-based bioactivity modules with attention mechanisms.
        <br />
        <br />
        Before joining Ro5, Orestis spent years working as a software engineer,
        in companies such as at the Good News Service and Radiojar, gaining
        experience in machine learning and eventually transitioning into the
        field. He also developed advanced skills in integrating multiple
        projects with massive databases during prior projects with Lockheed
        Martin and the European Space Agency.
        <br />
        <br />
        Orestis refined his knowledge and expertise in artificial intelligence
        when completing his second master’s degree at University College London
        (UCL) in data science and machine learning. Focusing on the mathematical
        underpinnings of machine learning, he became passionate about the inner
        workings of neural network architectures and how to apply them to
        real-world situations. His first master’s, also from UCL, was in
        mechanical engineering with innovation and entrepreneurship. This degree
        laid the foundations for his problem-solving, finance, and project
        management skills.
      </>
    ),
  },
  {
    name: "Alvaro Prat",
    role: "academic",
    branch: "Principal ML Scientist",
    headline: "AI Chemistry",
    content: (
      <>
        Alvaro is a skilled scientist with strong team leadership and research
        capabilities. Driven by his passion to develop disruptive technology, he
        completed an MEng in Mechanical Engineering at Imperial College London,
        where he developed strong foundations in control systems, numerical
        analysis, and scientific computing. His aspiration to replicate
        intelligence drove him to pursue an MSc in Computing (Artificial
        Intelligence & Machine Learning), where he specialized in the fields of
        Natural Language Processing, Computer Vision, Deep Reinforcement
        Learning, and Generative Methods.
        <br />
        <br />
        At Ro5, Alvaro serves as a Machine Learning Scientist and project lead
        in the 3D De Novo platform and Retrosynthesis projects. He has a record
        of solid research through publications in international journals and
        conferences and enjoys tackling new problems with a creative approach.
        Previously, he was a researcher at the Rolls Royce Technology Centre,
        where he gained experience in stochastic processes and simulation
        theory. At VisionRT, he worked on creating computer vision algorithms
        for radiotherapy treatment.
        <br />
        <br />
        In his free time, he has also enjoyed partaking in international
        competitions, winning the Codalab Student 2020 Sentence Translation
        challenge and ranking highly in the Google Waymo 2020 Object Detection
        challenge.
      </>
    ),
  },
  {
    name: "Tim Kras",
    branch: "Principal Data Scientist",
    role: "academic",
    headline: "Knowledge Graph",
    content: (
      <>
        Prior to joining Ro5, Tim worked in asset management, where his deep
        interest in finance, data and patterns led him to develop analytical and
        trading applications which were used to run a nine-digit portfolio. And
        the longer he looked, the better he could see the immense potential of
        computational data science to create value across different industries,
        not only investment management.
        <br />
        <br />
        Tim leads Ro5’s efforts to generate novel insights through building and
        exploring massive knowledge graphs and databases from unstructured data,
        which involves design and development of the in-house natural language
        processing pipeline that integrates literature-derived information into
        Ro5’s knowledge base. This is a multi-faceted endeavor, spanning data
        science, computer science, data modeling, DevOps, as well as business
        and competitive analysis and relentless execution.
        <br />
        <br />
        Tim has earned his master's degrees in business administration from MIT
        and in computer science from Georgia Tech, and is a CFA charterholder.
      </>
    ),
  },
  {
    name: "Gintautas Kamuntavicius",
    branch: "ML Scientist",
    role: "academic",
    headline: "AI Chemistry",
    content: (
      <>
        Gintas’ road to academia started at an early age as he three times
        qualified for the International Physics Olympiad, receiving a bronze and
        two silver medals. This led to an undergraduate degree in maths at the
        University of Cambridge, which deepened his knowledge of quantum
        mechanics, statistical physics and other applied fields, grounding him
        in probability and statistics.
        <br />
        <br />
        Inspired by the achievements of OpenAI in the Dota 2 reinforcement
        learning task, Gintas realised he wanted to apply his learned analytical
        skills by delving into the world of AI, which culminated in his MSc in
        AI from the University of Edinburgh. During his master thesis, he worked
        on a pipeline capable of grouping related events in audio using deep
        learning and data-driven grouping criteria. This involved thinking
        critically about the issues convolutional networks face in terms of
        long-distance reasoning and implementing solutions to the problems that
        arise.
        <br />
        <br />
        In his free time, Gintas enjoys the creative process, drawing or playing
        guitar (ideally jamming with friends). Nothing, however, beats a classic
        pub night with good friends after a tiring day.
      </>
    ),
  },
  {
    name: "Siim Schults",
    branch: "Director of Software Engineering",
    role: "devops",
    headline: "Software Engineering",
    content: (
      <>
        Siim is currently consulting Ro5 as a Software Engineer and he will
        transition to the position of Director of Software Engineering to lead
        the productization of Ro5 software. Siim is a seasoned software engineer
        with 10 years of experience working front-end and back-end development,
        and research software productization. After studying Computer
        Engineering in Tartu University, he has collected skills and experience
        ranging from robotics and telecommunications to biological data
        analysis. Before joining Ro5, Siim worked at BenevolentAI, where he
        worked closely with scientists in productionization of omics data
        analysis pipelines and was responsible for improving the overall
        performance and reproducibility of the software platform.
        <br />
        <br />
        Siim has co-founded “Effective Altruism Estonia”, an organization that
        focuses on increasing the awareness and understanding of effective
        altruism and enabling people’s career and donation decisions to have a
        greater positive impact. He is also a mentor at Springboard, where he
        provides support in starting and advancing software developer careers,
        from the first lines of code to assisting in finding first and second
        jobs. When not working, Siim is exploring his whole genome/epigenome
        sequencing data, collecting his own health data, playing games, and
        dancing. Fun fact - Siim has been coaching a team of autonomous robots
        for the Robot Soccer World Cup twice.
      </>
    ),
  },
  {
    name: "Dainius Salkauskas",
    role: "devops",
    branch: "Principal Back End Engineer",
    headline: "Software Development",
    content: (
      <>
        Dainius, a backend web developer at Ro5, graduated from the University
        of Manchester with a bachelor's degree in computer science. Before Ro5,
        his work in algorithm development gave him an early advantage in
        industry knowledge. Throughout his career he has acquired and honed
        wide-ranging skills from machine learning to backend and frontend web
        development, to data processing and beyond. Outside of work, he enjoys
        paddle tennis, running and skiing, as well as travel and time with his
        friends.
      </>
    ),
  },
  {
    name: "Amit Setty",
    branch: "Front End Engineer",
    role: "devops",
    headline: "Software Development",
    content: (
      <>
        Through a fusion of an both empathetic and analytical pespectives of
        looking at life Amit is able to utilize his Computer Science skills to
        lead design and development of the front end of the Ro5 Application. His
        love for understanding the user to create a great user experience and
        his love for problem solving make him able to innovate and bring his
        heart to his work. Besides his work for Ro5, Amit is a co-founder of
        Krazy Coding, a coding school for kids. Away from work, Amit enjoys
        hiking, surfing, and playing guitar.
      </>
    ),
  },
  {
    name: "Jon Berke",
    role: "devops",
    branch: "Principal UX Developer",
    headline: "Software Development",
    content: (
      <>
        Jon Berke, author of six patents, is a creative expert in user
        experience (UX) design, software architecture, and startups--essentials
        in a company’s short- and long-term success. After earning a degree in
        cognitive science from UC San Diego, he helped build Forte, an early
        player enabling businesses to manage and build on online customer
        service. When Forte sold to Genesys Telecommunications, he served as
        senior software architect, uniting email, chat and browser functions in
        the company’s existing call center. After that, predating Google and
        Waze, he founded Sigalert, the highly popular internet-based traffic
        service. Eventually Sigalert sold to Westwood One, where Jon expanded
        the service to television and radio stations nationwide. Most recently
        as chief technology officer of Knowability, he designed and built a
        system to aggregate startup financial and business data to provide
        unique and insightful views of the startup’s health.
      </>
    ),
  },
];

export default teamData;
