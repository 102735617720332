import React from "react";

const advisoryData = [
  {
    name: "Jeffrey Kindler",
    roles: ["Former CEO, Pfizer"],
    bioRole: ["CEO, Centrexion Therapeutics"],
    content: (
      <>
        Jeffrey Kindler, former chairman and CEO of Pfizer, Inc., joined
        Centrexion Therapeutics as CEO having led for more than three decades in
        some of the world’s most recognized companies. He is a director on the
        boards of PPD, Perrigo Company PLC, Precigen, Inc. and Terns
        Pharmaceutical. He has served on the boards of SIGA Technologies Inc.
        and vTv Therapeutics Inc. Currently he chairs the GLG Institute, a
        membership-based learning community for leading executives, and advises
        several private health care firms. He is a senior advisor to Blackstone
        and an operating partner at Artis Ventures.
        <br />
        <br />
        Mr. Kindler led Pfizer’s $68 billion merger and acquisition of Wyeth,
        diversified Pfizer’s product portfolio, improved its research and
        development pipeline, and reshaped its commercial, innovation and
        leadership models to drive growth and cultural change. Earlier he was
        executive VP and general counsel for McDonald’s Corp., VP of litigation
        and legal policy for the General Electric Co., and a partner at William
        & Connolly in civil and criminal litigation. He holds a B.A. from Tufts
        University and a J.D. from Harvard University.
      </>
    ),
  },
  {
    name: "Tuvia Barak",
    roles: ["Venture Partner, Lux Capital"],
    bioRole: [
      "Venture Partner, Lux Capital, Lytical Ventures",
      "Board Member, Skyline Software, Wipe Drive, Aveta.Lifes",
    ],
    content: (
      <>
        Tuvia Barak has served as a Venture Partner with Lux Capital since 2005.
        In 1982, Mr. Barak joined Tadiran, Inc., the North American subsidiary
        of Tadiran, the largest electronics conglomerate in Israel. During his
        12-year tenure, over $3 billion in revenue was achieved under his
        direction, and Tadiran became one of the major suppliers of tactical
        communications to the U.S. Army. Mr. Barak developed strategic alliances
        and partnerships with some of the largest aerospace and defense
        companies. In 1993, Mr. Barak established his own marketing and
        consulting practice, advising U.S. and international companies on
        business development, marketing strategies, technology assessment, and
        mergers and acquisitions in the aerospace and technology fields.
        <br />
        <br />
        Mr. Barak is a member of the Board of Directors of Aveta.life, Skyline
        Software, Toonimo, WhiteCanyon Software, and Weatherbell Analytics, and
        special advisor to Astra Capital Management, and Heller Industries. He
        previously served as a Board Director at Brashear LP., Fairchild
        Imaging, and Arinc Corp., all of which were acquired by major companies
        with exceptional returns to their investors. His many clients have
        included The Carlyle Group, Boeing, L-3 Communications, ITT, General
        Dynamics, AAI/Textron, BAE, EDS, DRS, Elbit Systems, Schott, Selex, Ness
        Technologies, Vought Aircraft, SanDisk, United Defense, Mantech
        International, NanoSys, MapInfo, Rockwell Collins and Raytheon. Mr.
        Barak has a M.Sc. in E.E. from the Technion Institute of Technology,
        Israel.
      </>
    ),
  },
  {
    name: "Carol Routledge, Ph.D.",
    roles: ["Former Head of Translational Medicine, GSK"],
    bioRole: ["Chief Medical and Scientific Officer, Small Pharma Ltd."],
    content: (
      <>
        Dr. Carol Routledge, R&D professional and drug development expert, for
        more than 30 years has led UK- and U.S.-based pharmaceutical and
        biotechnology companies and nonprofits in drug acquisition and profiling
        of NCEs and biologics.
        <br />
        <br />
        In far-reaching work to understand and treat mental health disorders,
        Dr. Routledge’s preclinical and clinical backgrounds span both
        psychiatric and neurological indications. At Syntex, Wyeth, BTG, and
        GlaxoSmithKline, she led drug discovery and development activities
        across multiple therapeutic areas related to immunoinflammatory diseases
        and neuroscience, emphasizing translational medicine. She has managed
        and sourced opportunities at a semi-philanthropic dementia discovery
        fund and led preclinical and clinical research strategy across
        Alzheimer’s Research UK for response-mode, strategic funding, and for
        global projects. As managing director, she built and led an independent
        and global initiative for early detection of neurodegenerative diseases
        (EDoN).
        <br />
        <br />
        Dr. Routledge has a first-class honors degree in zoology from Nottingham
        University and a PhD in neuropharmacology. She is the primary author of
        more than 70 papers in scientific journals or meetings and holds a
        biology patent.{" "}
      </>
    ),
  },
  {
    name: "John Howe, Ph.D. ",
    roles: ["Principal Scientist, Infectious Diseases", "and Vaccines, Merck"],
    bioRole: [
      "Principal Scientist, Infectious Diseases",
      "and Vaccines, Merck",
    ],
    content: (
      <>
        John Howe is a principal scientist at Merck in the department of
        Infectious Diseases and Vaccines with extensive experience in biotech
        and small molecule drug discovery and development. In his previous role
        at Merck, Dr. Howe led biology teams in hepatitis C virus (HCV)
        antiviral drug discovery / lead optimization and life-cycle management
        projects. He was a member of the team that gained the regulatory
        approval of Victrelis, the first direct-acting antiviral for HCV
        treatment. Currently, he continues to lead teams of researchers working
        on projects to develop novel antivirals that address unmet medical
        needs.
        <br />
        <br />
        Before Merck, Dr. Howe served as a principal scientist at
        Schering-Plough Research Institute, where he directed HIV antiviral
        discovery projects and characterization of drug resistance. As an
        associate principal scientist, he developed cancer gene therapy vectors
        and production cell lines. Dr. Howe conducted post-doctoral research at
        UC San Diego focused on cell cycle research and completed his Ph.D. in
        biology at McMaster University in Ontario, Canada where he studied
        oncogenic transformation by the adenovirus E1a gene.
        <br />
        <br />
        Dr. Howe’s more than 50 publications include a 2015 cover-featured
        article in Nature entitled “Selective Small-Molecule Inhibition of an
        RNA Structural Element,” in which his team demonstrated the tractability
        of non-coding RNA as a drug target.
      </>
    ),
  },
  {
    name: "Timothy Tully, Ph.D.",
    roles: ["Former EVP of R&D and CSO, Dart Neuroscience"],
    bioRole: [
      "Adjunct Professor",
      "Tsinghua University, China",
      "National Tsing Hua University, Taiwan",
    ],
    content: (
      <>
        Dr. Timothy Tully, former executive vice president of R&D and chief
        science officer of Dart Neuroscience, is a career expert in the genetic
        basis of memory.
        <br />
        <br />
        In 1994, Dr. Tully and his group at Cold Spring Harbor Laboratory made
        news with their discovery of the CREB gene’s important role in
        regulating the conversion of short-term memory to long-term memory,
        suggesting that small-molecule modulators of the CREB pathway might
        enhance human memory and help treat memory-related disorders such as
        Alzheimer's disease. In 1997, aided by Nobel Prize-Winner James Watson
        (he and Francis Crick discovered the double-helix structure of the DNA
        molecule), Dr. Tully founded Helicon Therapeutics, Inc. and was acting
        chief of science until Helicon merged with Dart NeuroScience LLC.
        <br />
        <br />
        Dr. Tully received B.S. degrees in biology and psychology from the
        University of Illinois, as well as a Ph.D. in genetics. He pursued
        postdoctoral training in neurogenetics at Princeton University and in
        molecular genetics at MIT. Following that he was an assistant professor
        at Brandeis University, then joined the staff of Cold Spring Harbor
        Laboratory as an associate professor. He became a professor there, then
        the St. Giles Foundation Professor of Neuroscience, and then headed the
        neurogenetics division until he left to serve as chief science officer
        of Dart Neuroscience LLC.
        <br />
        <br />
        Dr. Tully is an adjunct professor at Tsinghua University in Beijing
        China and at the National Tsing Hua University in Hsinchu, Taiwan. He
        has numerous published scientific papers and is on the editorial board
        of multiple neuroscience journals. His research has drawn many.
      </>
    ),
  },
  {
    name: "Steven M. Katzman, M.D.",
    roles: ["Board Certified, Internal Medicine", "and Gastroenterology"],
    bioRole: ["Board Certified, Internal Medicine", "and Gastroenterology"],
    content: (
      <>
        Dr. Steven M. Katzman, board certified in internal medicine and
        gastroenterology, practiced for 24 years with Texas Digestive Disease
        Consultants, one of the largest single-specialty groups in the nation.
        <br />
        <br />
        His research extends from a surgical atlas in head-and-neck cancer; to
        correlating CT imaging with clinical and pathologic staging of laryngeal
        cancer; to rheumatology trial research studying NSAID-induced
        gastrointestinal injury. He participated in clinical instruction for
        medical students and residents.
        <br />
        <br />
        Dr. Katzman’s leadership in the business of healthcare includes
        ground-up development of medical facilities. Currently he serves on the
        board of directors of the Irving Hospital Authority, which establishes,
        maintains and provides medical services and facilities for the citizens
        it serves. The board currently presides over a $170 million project to
        update and expand the regional medical campus and its services.
        <br />
        <br />
        Born and raised in Mexico City, Dr. Katzman studied there at the
        American School Foundation. He graduated from medical school at
        Universidad Autonoma de Guadalajara, Mexico. He completed his internal
        medicine residency and chief residency at the University of
        Miami-affiliated hospitals in Miami, Florida. His gastroenterology
        fellowship was at The University of Texas Southwestern Medical Health
        Science Center in Dallas.{" "}
      </>
    ),
  },
  {
    name: "Robert Collins, M.D.",
    roles: [
      "Director, Blood Cancers / Transplant Therapies",
      "and Stem Cell Transplant Program",
      "UT Southwestern Medical Center",
    ],
    bioRole: [
      "Internal Medicine",
      "Hematology/Oncology",
      "Bone Marrow Transplant",
      "Leukemia Treatment",
    ],
    content: (
      <>
        Robert H. Collins Jr., M.D., FACP--board certified in internal medicine
        and medical oncology--teaches Internal Medicine at UT Southwestern
        Medical Center, where he directs both the Blood Cancers/Transplant and
        Cellular Therapies program and the Combined Adult/Pediatric Stem Cell
        Transplant program.
        <br />
        <br />
        Dr. Collins holds the Sydney and J.L. Huffines Distinguished Chair in
        Cancer Research in Honor of Eugene Frenkel, M.D. He is the R. Lloyd and
        Willye V. Skaggs Professor in Medical Research. A recognized leader in
        adoptive immunotherapy, Dr. Collins specializes in blood and marrow
        transplantation and hematologic malignancies such as leukemia, lymphoma,
        myeloma, Hodgkin’s disease, and myelodysplasia. He has 25 years of
        experience in bone marrow transplantation, having participated in some
        2,500 transplants.
        <br />
        <br />
        Dr. Collins has led studies of donor leukocyte infusions through an
        international network of more than 100 centers. He continues to lead an
        active program of phase I-III clinical trials investigating novel
        approaches to hematologic malignancies and blood and marrow
        transplantation. After earning his medical degree at the University of
        Missouri-Kansas City School of Medicine, he completed a residency in
        internal medicine at Baylor University Medical Center in Dallas,
        followed by a fellowship at UCLA in hematology and oncology. He is a
        fellow of the American College of Physicians and a member of scientific
        and medical organizations such as the American Association for the
        Advancement of Science, the American Society of Hematology, the American
        Society of Clinical Oncology, and the American Society for Blood and
        Marrow Transplantation.
        <br />
        <br />
        U.S. News & World Report named Dr. Collins a Best Doctor. He is listed
        in Best Doctors in America and is a Texas Monthly Super Doctor. In 2018,
        D Magazine ranked him in its Best Doctors. Among many honors, he
        received the John J. Kenny Award from the Leukemia & Lymphoma Society.
      </>
    ),
  },
  {
    name: "William C. Collins",
    roles: [
      "Managing Director and Cofounder of Brook Point Advisors",
    ],
    bioRole: [
      "Managing Director and Cofounder of Brook Point Advisors",
    ],
    content: (
      <>
        William Collins is a Managing Director and Cofounder of Brook Point
        Advisors, a Dallas-based M&A middle-market advisory firm. He has
        executed numerous merger and acquisition and capital raising assignments
        in a wide variety of industries. Prior to founding Brook Point in 2015,
        Mr. Collins had a 14-year career as President and Managing Director with
        Frost Securities where he provided M&A advisory and capital raising
        services to privately held, middle-market and early-stage companies.
        Prior to joining Frost Securities, he was a Managing Director of
        Investment Banking for Banc of America Securities, where he managed
        middle-market public underwriting offerings, leveraged transactions, and
        M&A transactions in the technology sector. He has been involved in
        investment banking and leveraged finance advisory since 1983.
        <br />
        <br />
        Mr. Collins also is an investor in the health sciences sector. He earned
        his MBA from Northwestern University’s Kellogg School of Management and
        an A.B. from Stanford University.
      </>
    ),
  },
];

export default advisoryData;
